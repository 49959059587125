<template>
    <div>
        <table class="table table-striped ecart-table" v-show="!loading.charges">
            <tbody>
                <tr v-for=" (charge,index) in paymentsCharges " :key="index">
                    <td>
                        <div class="row justify-content-center alitn-items-center"
                            :class=" charge.showCharges ? 'mb-md-2' : '' ">
                            <div class="col-auto pr-2 ">
                                <button class="btn text-muted btn-xs rounded-pill "
                                    @click="charge.showCharges = !charge.showCharges">
                                    <i class="fa fa-lg"
                                        :class=" charge.showCharges ?  'fa-times-circle' : 'fa-chevron-circle-down' "></i>
                                </button>
                            </div>
                            <div class="col pl-0 ">
                                <p class="m-0">
                                    <strong v-text=" charge.number "></strong>
                                </p>
                            </div>
                            <div class="col-auto">
                                <p class="f-w-500">
                                    <span v-text=" fnFormatPrice( charge.total_charges, '' ) "></span>
                                </p>
                            </div>
                        </div>
                        <div class="animated fadeIn" v-show="charge.showCharges">
                            <table class="table table-sm table-bordered d-none d-md-table text-center  ">
                                <thead>
                                    <tr>
                                        <td class="text-left" v-text="$t('tables.name')"></td>
                                        <td v-text="$t('tables.type')"></td>
                                        <td v-text="$t('tables.status')"></td>
                                        <td v-text="$t('tables.date')"></td>
                                        <td v-text="$t('tables.amount')"></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for=" (subCharge,index) in  charge.charges" :key="index">
                                        <td class="text-left" v-text="subCharge.name"> </td>
                                        <td v-text="subCharge.type"> </td>
                                        <td v-text="subCharge.status"> </td>
                                        <td v-text=" fnDate( subCharge.date , CONST.DATE_FORMATS.DD_MM_YYYY ) "> </td>
                                        <td v-text="fnFormatPrice( subCharge.amount, '' )  "> </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="row d-md-none ">
                                <div class="col-12">
                                    <hr>
                                </div>
                                <div class="col-12" v-for=" (subCharge,index) in  charge.charges" :key="index">
                                    <div class="row">
                                        <div class="col-6">
                                            <p v-text="subCharge.name"></p>

                                        </div>
                                        <div class="col-6">
                                            <p class="text-right" v-text="fnFormatPrice( subCharge.amount, '' ) "></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
         <no-data :dataMessage="$t('noDataMessages.chargesList')"
            v-show=" loading.charges || (paymentsCharges.length == 0) " :isLoading="loading.charges"
            :showBtn="false">
        </no-data>

    </div>
</template>

<script>
    import {
        mapActions,
        mapState
    } from 'vuex'
    export default {
        data() {
            return {}
        },
        computed: {
            ...mapState('payments', ['paymentsCharges','loading']),
        },
        methods: {
            ...mapActions('payments', ['fnApiGetPaymentsCharges']),
        },
        mounted() {
            this.fnApiGetPaymentsCharges();
        }
    }
</script>

<style>

</style>