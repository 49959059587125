<template>
  <div class=" h-100-vh ">
    <div class="card card-ecart" v-show="integration">
      <div class="card-header">
        <h5 v-text=" $t('dashboard.payments.title') "> </h5>
      </div>
      <div class="card-body">
          <Integration skipBtn @user-complete="fnApiUserComplete" />
      </div>
    </div>
    <div class="row h-100" v-show="!integration">
      <div class="col-12 col-lg-5 col-xl-4">

        <b-tabs class="card ecart-tabs" justified>
          <b-tab :title="$t('general.button.balances')" active>
            <Balance class="animated fadeIn " />
          </b-tab>
          <b-tab :title="$t('dashboard.payments.myAccounts')">
            <Accounts class="animated fadeIn " @user-complete="fnApiUserComplete" />
            <PaymentInvoice v-show=" user.country == 'MX' " />
          </b-tab>
        </b-tabs>

      </div>

      <div class="col-12 col-lg-7 col-xl-8">

        <b-tabs class="card ecart-tabs" justified>
          <b-tab :title="$t('dashboard.payments.recentActivity')" active>
            <PaymentsActivity class="animated fadeIn " />
          </b-tab>
          <b-tab :title="$t('general.charges')">
            <PaymentsCharges class="animated fadeIn "  />
          </b-tab>
        </b-tabs>
      </div>
    </div>

  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";

  import Accounts from './components/accounts';
  import Balance from './components/balance';
  import PaymentInvoice from './components/payments-invoice';
  import Integration from './components/integration';
  import PaymentsActivity from './components/payments-activity';
  import PaymentsCharges from './components/payments-charges';
  

  export default {
    components: {
      Accounts,
      Balance,
      PaymentInvoice,
      Integration,
      PaymentsActivity,
      PaymentsCharges,
    },
    data() {
      return ({
        showMore: false,
      })
    },
    computed: {
      ...mapGetters('payments', ['getMethodsPaypal', 'getMethodsDebit', 'getMethodsClabe', 'getBalancesFilter',
        'getTotalBalances'
      ]),
      ...mapState('payments', ['exchanges', 'showAccounts']),
      ...mapState('session', ['user']),
      ...mapState(['device', 'integration']),
    },

    methods: {
      ...mapMutations('payments', ['setPaymentStep', 'resetNewPayment', 'changeView']),
      ...mapActions('payments', ['fnApiGetPayouts', 'fnApiCreatePayment',
        'fnApiGetMthods', 'fnApiGetBanks', 'fnApiCloseOrders'
      ]),
      ...mapMutations(['setIntegration']),

      ...mapMutations('menu', ['setStepNumber']),
      ...mapMutations('session', ['setSession']),
      ...mapActions('session', ['fnGetUserInfo']),

      async fnApiUserComplete() {
        await axios.put('me/complete').then(async response => {
          localStorage.setItem('user', JSON.stringify(response.data))
          await this.setSession(response.data);
          setTimeout(() => {
            window.location.replace('/dashboard/welcome');
          }, 200)
        }).catch(error => error);
      },
    },
    async beforeMount(){
      if (this.$route.query.integration) {
        this.setIntegration(true);
        this.setStepNumber(4);

        if (this.user.country != 'MX') {
          await this.fnApiUserComplete();
        }
      }
    },

    async mounted() {
      await this.fnGetUserInfo();
      
      await this.fnApiCloseOrders();
      this.fnApiGetPayouts();
      this.fnApiGetMthods();
      this.fnApiGetBanks();
    }
  }
</script>
