<template>
    <div>
        <div class="row justify-content-end align-items-end">
            <div class="col-auto">
                <button class="btn btn-success rounded-pill" @click=" fnGetExcel( ) " :disabled='loading.document'>
                    <i class="fa fa-circle-notch fa-spin mr-2 ainmated fadeIn" v-show="loading.document"></i>
                    <span v-text=" $t('general.button.export')  " class="mr-2"> </span>
                    <i class="fa fa-file-excel"></i>
                </button>
            </div>
        </div>
        <div v-show=" !loading.paymentsList && paymentList.length > 0">

            <!-- Mobile -->
            <table class="table-striped table ecart-table d-md-none">
                <tbody>
                    <tr v-for=" (payment,index) in paymentList" :key="index">
                        <td>
                            <div class="row">
                                <div class="col-6">
                                    <p>
                                        <b v-text=" payment.transaction_id "></b> <br>
                                        <small class="text-capitalize "
                                            v-text=" $t('dashboard.payments.status.' + payment.status) "
                                            :class="payment.status == 'paid' ? 'text-success':'text-warning' ">
                                        </small>
                                    </p>
                                </div>
                                <div class="col-6 text-right">
                                    <p class="f-w-600"
                                        v-text="fnDate( payment.created_at , CONST.DATE_FORMATS.DD_MM_YYYY )">
                                    </p>
                                    <p v-text="fnFormatPrice( payment.amount, payment.currency  ) "></p>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>



            <!-- Desktop -->
            <table class="table-striped table ecart-table">
                <tbody>
                    <tr v-for=" payment in paymentList" :key="payment.id">
                        <td class="text-center f-w-500" v-text=" fnDate( payment.created_at , CONST.DATE_FORMATS.DD_MM_YYYY ) "> </td>
                        <td>
                            <p>
                                <b v-text=" payment.transaction_id "></b> <br>
                                <small class="text-capitalize "
                                    v-text=" $t('dashboard.payments.status.' + payment.status) "
                                    :class="payment.status == 'paid' ? 'text-success':'text-warning' ">
                                </small>
                            </p>
                        </td>
                        <td v-text=" fnFormatPrice( payment.amount, payment.currency  ) "> </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <no-data :dataMessage="$t('noDataMessages.paymentsList')"
            v-show=" loading.paymentsList || (paymentList.length == 0) " :isLoading="loading.paymentsList"
            :showBtn="false">
        </no-data>
    </div>
</template>

<script>
    import {
        mapActions,
        mapState
    } from 'vuex';
    export default {
        computed: {
            ...mapState('payments', ['paymentList', 'loading']),
            ...mapState(['device']),
        },
        methods: {
            ...mapActions('payments', ['fnApiGetExcel']),
            fnGetExcel() {
                if (this.device) {
                    DownloadFile.postMessage(JSON.stringify({
                        auth: true,
                        url: window.location.origin + `/seller/payouts/summary`,
                        fileName: this.$moment().format('DD-mm-YYYY') + '.xls',
                    }));
                } else {
                    this.fnApiGetExcel();
                }
            },
        }
    }
</script>

<style>

</style>