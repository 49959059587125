<template>
  <div class="form-rounded-inputs">
    
    <div class=" text-center  m-t-50  m-b-50">
      


      <h5 v-text=" $t('dashboard.payments.integration.title')" class="mb-2"> </h5>
      <div class="row">
        <div class="col-12 col-sm-10 col-md-8 mx-auto">
          <p v-text=" $t('dashboard.payments.integration.message') "> </p>
        </div>
      </div>

      <LanguageLoader class="scroll-contianer border p-2 mt-2 mx-0 mx-md-5 text-muted" v-if="user.country == 'MX' "
        routeLanguage="./translations/payments/payments-taxes_" />

      <div class="row flex-column justify-content-center align-items-center ">
        <div class="col-auto">
          <p class="my-4">
            <button class=" btn btn-success mb-2" @click=" changeModal({paymentMethod:true}) ">
              <strong v-text=" $t('dashboard.payments.integration.btn') "></strong>
            </button>
            <br>
            <button class="btn btn-light w-100" @click=" $emit('user-complete') " v-if=" skipBtn ">
              <strong v-text=" $t('general.button.skip') "></strong>
            </button>
          </p>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
  import LanguageLoader from '@/components/TranslationsLoader.vue';
  import {
    mapMutations, mapState,
  } from "vuex";

  export default {
    components: {
      LanguageLoader,
    },
    props: {
      skipBtn: {
        type: Boolean,
        required: true,
        default: false,
      }
    },
    data() {
      return ({});
    },
    computed: {
      ...mapState('session', ['user']),
    },
    methods: {
      ...mapMutations('payments', ['changeModal']),
    }
  }
</script>


<style lang="scss" scoped>
.scroll-contianer{
  max-height: 200px;
  overflow-y: scroll;
}
</style>