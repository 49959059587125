<template>
  <div class="">
    <div class="row align-items-center mb-3">
      <div class="col">
        <p class="f-14"> <strong v-text="$t('general.form.invoiceInformation')"></strong> </p>
      </div>
      <div class="col-auto">
        <button class="btn btn-success circle-25 p-0" @click="changeModal( { invoice : true} )">
           <i class="fa fa-plus-circle "></i>
        </button>
      </div>
    </div>

    <table class="table table-striped">
      <thead>
        <tr>
        </tr>
      </thead>
      <tbody>
        <tr v-for=" (invoice,index) in  invociesList" :key="index ">
          <td>
            <div class="row justify-content-between ">
              <div class="col-10">
                <p class="m-0 text-truncate">
                  <strong class="" v-text=" 'RFC: ' "></strong> <span v-text="invoice.key"></span>
                </p>
                <p class="m-0 text-truncate">
                  <small v-text=" $t('dashboard.order.reason') + ': ' + invoice.reason"></small> <br>
                </p>
                <p class="m-0 text-truncate">
                  <small v-text=" $t('general.form.businessName') + ': ' + invoice.business_name"></small>
                </p>
              </div>
              <div class="col-auto">
                <button class="btn btn-danger circle-20 btn-xs" @click="fnDeleteInvoice(invoice.id)">
                  <i class="fa fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>


    <div class="d-flex my-2" v-if="invociesList.length == 0">
      <div class="linedoted mt-auto"></div>
      <p class="m-0 text-muted" v-text="$t('general.withoutInformation')"></p>
    </div>
    <InvoiceModal />
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";
  import InvoiceModal from './payment-invoice-modal';
  export default {
    components: {
      InvoiceModal,
    },
    computed: {
      ...mapState('payments', ['invociesList', 'newInvoiceData']),
    },
    methods: {
      ...mapMutations('payments', ['changeModal', 'updateInvoice']),
      ...mapActions('payments', ['fnDeleteInvoice']),
      fnUpdateInvoice(index) {
        this.updateInvoice(this.invociesList[index]);
        this.changeModal({
          invoice: true
        })
      }
    }
  }
</script>

<style>

</style>